$bottom-logo-width: 100px;
$bottom-logo-width-500: 120px;
$bottom-logo-width-768: 150px;
$bottom-logo-width-992: 180px;
$bottom-logo-width-max: 210px;

#footer {
    //position: relative;

    .bottom-line {
        height: 5px;
        background-color: $text-highlight;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .footer-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;

        .fm-line {
            border-bottom: 1px solid $line-color;
            line-height: 0.1em;
            flex-grow: 1;
        }

        .fm-msg {
            padding: 0 1rem;
        }
    }

    .footer-partners {
        padding: 5px 15px;
        height: auto;

        .partner {
            display: flex;
            justify-content: center;

            img {
                height: 100%;
                width: auto;
                max-height: 56px;
            }
        }
        .border-separator {
            border-bottom: 4px solid $giallo-ocra;
            height: 15px;
        }
        .block-sponsors {
            .sponsor-main {
                display: flex;
                justify-content: space-around;
            }
            .sponsor-official {
                display: flex;
                justify-content: space-around;
            }
        }
    }

    &.with-logo {
        .inner {
            padding: 0 15px;
            max-width: 400px;
            margin: 0 auto;
        }

        .bottom-logo {
            position: absolute;
            right: 0;
            bottom: 0px;
            width: $bottom-logo-width;
        }

        .footer-message {
            font-size: 14px;
        }
        .bottom-line {
            width: calc(100vw - #{$bottom-logo-width});
        }

        .footer-partners {
            padding-top: 1rem;
            padding-bottom: 4rem;
        }
    }

    &.no-logo {
        .inner {
            padding: 15px;
            width: auto;

            max-width: 600px;
            margin: 0 auto;
        }

        .footer-message {
            font-size: 14px;
        }

        .footer-partners {
            padding-top: 0.5rem;
            padding-bottom: 1rem;
        }

        .bottom-logo {
            display: none;
        }
        .bottom-line {
            width: 100%;
        }
    }
    .text-sponsor {
        font-size: 12px;
    }
}

#footer.footer-img {
    position: relative;
    text-align: center;
    padding: 1rem 0;

    .footer-logo {
        display: inline-block;
        margin: 0 auto;
        width: 75px;
    }
}

.block-sponsors {
    margin-top: 15px;
    justify-content: space-around;
}

@media screen and (min-width: 500px) {
    #footer {
        &.with-logo {
            .wrapper {
                width: calc(100vw - #{$bottom-logo-width-500});
                text-align: center;
            }
            .inner {
                padding: 0;
                margin: 0 auto;
                max-width: 370px;
            }

            .footer-partners {
                padding-top: 0.5rem;
                padding-bottom: 1rem;
            }

            .bottom-logo {
                width: $bottom-logo-width-500;
            }

            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-500});
            }
        }

        &.no-logo {
            .footer-message {
                font-size: 16px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #footer {
        .footer-partners {
            padding: 5px 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 75px;
            .partner {
                img {
                    max-height: 43px;
                }
                margin: 0 5px;
            }
            .border-separator {
                border-right: 4px solid $giallo-ocra;
                border-bottom: none;
                height: 50px;
            }
            .block-sponsors {
                &.layout-fix {
                    display: flex;
                    justify-content: space-around;
                }
                .sponsor-main {
                }
                .sponsor-official {
                    display: contents;
                }
            }
        }
        &.with-logo {
            .wrapper {
                width: calc(100vw - #{$bottom-logo-width-768});
            }
            .inner {
                max-width: 500px;
            }

            .footer-partners {
            }

            .bottom-logo {
                width: $bottom-logo-width-768;
            }

            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-768});
            }
        }
    }

    #footer.footer-img {
        .footer-logo {
            width: 100px;
        }
    }
    .block-sponsors {
        margin-top: 0;
        flex-grow: 1;
    }
}

@media screen and (min-width: 992px) {
    #footer {
        .footer-partners {
            padding: 5px 5rem;
            .partner {
                img {
                    max-height: 43px;
                }
            }
        }

        &.with-logo {
            .wrapper {
                width: 100%;
            }
            .inner {
                max-width: 500px;
            }

            .footer-partners {
                .partner {
                    img {
                        max-height: 43px;
                    }
                }
            }

            .bottom-logo {
                width: $bottom-logo-width-992;
            }

            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-992});
            }
        }
        .text-sponsor {
            font-size: 18px;
        }
    }

    #footer.footer-img {
        text-align: left;
        padding: 1rem 2vw;

        .footer-logo {
            display: inline-block;
            margin: 0;
            width: 100px;
        }
    }
}

@media screen and (min-width: 1200px) {
    #footer {
        .footer-partners {
            .partner {
                img {
                    max-height: 55px;
                }
            }
            .border-separator {
            }
        }
        &.no-logo {
            .inner {
                padding: 0 15px;
                max-width: 600px;
                margin: 0 auto;
            }
            .footer-message {
                font-size: 16px;
            }
        }

        &.with-logo {
            .footer-message {
                font-size: 16px;
            }
            .inner {
            }
            .bottom-logo {
                width: $bottom-logo-width-max;
            }

            .footer-partners {
                padding-bottom: 2rem;
            }
            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-max});
            }
        }
    }
}
@media screen and (min-width: 1400px) {
    #footer {
        .footer-partners {
            .partner {
                img {
                    max-height: 75px;
                }
            }
        }
    }
}
