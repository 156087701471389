.page-bck {
    background-color: $background-color;
    background-size: contain;
}

h1,
h2,
h3 {
    font-weight: bold;
}

h1.title {
    font-size: 20px;
}

hr {
    border: 2px solid black;
    width: 50%;
}

.title-filepond {
    font-size: 14px;
}

.subtitle-filepond {
    font-size: 11px;
}
.filepond--root {
    --h: 40px;

    margin-bottom: 0;
    width: 100%;
    max-height: var(--h);
    height: var(--h);

    @media (min-width: 768px) {
        width: 50%;
        margin-left: auto;
    }
    .filepond--drop-label {
        min-height: var(--h);
        color: $text-color;
        background: #f2f2f2;
        border-radius: 5px;
        cursor: pointer;
    }

    .filepond--item {
        margin: 0;
        height: var(--h);
    }

    .filepond--list.filepond--list {
        width: 100%;
        left: 0;
    }
}

.filepond--panel {
    height: var(--h) !important;
}

.filepond--root .filepond--list-scroller {
    margin-top: 0;
    margin-bottom: 0;
}
#view {
    min-height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    max-width: 2440px;
    margin: 0 auto;
}

#view.view-vertical {
    display: flex;
    flex-direction: column;
}

#view.view-horizontal {
    height: 100%;
}
.conference-color {
    color: $itw-magenta !important;
}
.workshop-color {
    color: $itw-verde !important;
}
.community-color {
    color: $itw-viola !important;
}
.extra-events-color {
    color: black !important;
}
.m2o-color {
    color: $itw-rosso !important;
}

button.conference-color:hover,
button.workshop-color:hover,
button.community-color:hover {
    color: white !important;
}

a.conference-color:hover,
a.workshop-color:hover,
a.community-color:hover {
    color: white !important;
}

//a.conference-color:hover {
//    color: black !important;
//}

#main {
    padding: 1rem;
    flex-grow: 1;

    &.main-content-h {
        display: flex;
        flex-direction: column;
        //min-height: 100%;
    }

    &.main-content-v {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        //margin-top: 40px;
        &.centered {
            justify-content: center;
        }
    }

    &.main-content-vl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}

.left-content {
    max-width: 700px;
}

#header-box {
    display: flex;
    align-items: center;

    .logo-main {
        margin-right: 2rem;
    }

    .logo {
    }
}

#content-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
}

#countdown-box {
    // background-color: #fff;
    // box-shadow: 0px 10px 20px #000;
    max-width: 950px;
    margin: 0 auto;

    h1 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 35px;
    }

    .content {
        text-align: center;

        .cmsg {
            color: $text-color;
            font-weight: bold;
            margin-top: 15px;
        }
    }
}

#footer-box {
    .footer-logo {
        width: 120px;
    }
}

#logo-message-box {
    .logo-box {
        width: 120px;
        margin: 0 auto;
    }

    .secondary-logo-box {
        margin: 1rem auto;
        width: 235px;

        .shiny {
            display: block;
            width: 35%;
            margin: auto;
        }
    }

    .message-box {
        margin: 1rem 0;

        .message,
        .msg-1,
        .msg-2,
        .msg-3 {
            line-height: 1;
        }

        .message {
            font-size: 16px;
        }

        .msg-1 {
            font-size: 28px;
        }

        .msg-2 {
            font-size: 26px;
        }

        .msg-3 {
            font-size: 22px;
        }
    }
}

.omg {
    color: $text-color;
}

.omg-size {
    font-size: 16px;
}

#form-box {
    //margin-top:   2rem;
    //max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    .content {
        padding: 28px 1rem;
        background-color: #fff;
        position: relative;
        color: $text-color;

        &.large-padding {
            padding-top: 38px;
        }
    }

    .itw-border {
        position: relative;
        box-sizing: border-box;
        color: $text-color;
        background-clip: padding-box; /* !importanté */
        border: solid 5px transparent; /* !importanté */

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -5px; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: linear-gradient(to bottom right, $itw-viola, $itw-verde, $itw-magenta);
            transform: translate3d(0px, 20px, 0) scale(0.95);
            filter: blur(30px);
            opacity: var(0.7);
            transition: opacity 0.3s;
        }
        .inner {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: -5px; /* !importanté */
                border-radius: inherit; /* !importanté */
                background: linear-gradient(to bottom right, $itw-viola, $itw-verde, $itw-magenta);
            }
        }
    }

    // max-width: 500px;
    // margin: 0 auto;

    // box-shadow: 0px 10px 20px #000;
}

.page-title {
    position: relative;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 30px;
    display: inline-block;

    &:after {
        content: '';
        width: 120px;
        height: 5px;
        background-color: $text-highlight;
        position: absolute;
        bottom: -20px;
        left: 0;
    }
}

#message-box {
    //padding: 1.5rem;
    // background-color: #fff;
    // box-shadow: 0px 10px 20px #000;
    text-align: center;
    img {
        max-width: 900px;
    }
    h3 {
        font-size: 22px;
    }
    .content {
        padding: 48px 1rem;
        background-color: $itw-grigio;
        position: relative;

        &.large-padding {
            padding-top: 38px;
        }
    }

    .itw-border {
        position: relative;
        box-sizing: border-box;
        color: $text-color;
        background-clip: padding-box; /* !importanté */
        border: solid 5px transparent; /* !importanté */

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -5px; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: linear-gradient(to bottom right, $itw-viola, $itw-verde, $itw-magenta);
            transform: translate3d(0px, 20px, 0) scale(0.95);
            filter: blur(50px);
            opacity: var(0.7);
            transition: opacity 0.3s;
        }
        .inner {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: -5px; /* !importanté */
                border-radius: inherit; /* !importanté */
                background: linear-gradient(to bottom right, $itw-viola, $itw-verde, $itw-magenta);
            }
        }
    }

    .main-title {
        //border-bottom: 3px solid $text-highlight;
        font-size: 48px;
        padding: 15px 0;
        margin: 0;
    }

    .divider {
        width: 100px;
        border-bottom: 2px solid;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    .msg {
        font-size: 24px;
    }

    .main-title,
    .message,
    .msg-1,
    .msg-2,
    .msg-3 {
        line-height: 1;
    }

    .message {
        font-size: 16px;
    }

    .msg-1 {
        font-size: 35px;
    }

    .msg-2 {
        font-size: 26px;
    }

    .msg-3 {
        font-size: 16px;
        text-align: center;
    }

    .inner {
        &.success,
        &.error {
            padding: 1rem 1.5rem;
        }

        &.success {
            //background-color: $repidee-azzurro;
            //color: $text-reverse;
        }

        &.error {
            border-color: $text-error;
        }
    }

    .omg {
        font-size: 16px;
    }

    .btn-form-submit {
        //border: 2px solid $text-color;
    }
}

.page-embed {
    color: #000;

    #form-box {
        box-shadow: none;
    }

    #message-box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        box-shadow: none;

        .logo-embed {
            width: 100px;
            margin: 1rem auto;
        }
    }
}

.pharma-name,
.pharma-data {
    line-height: 1;
}

#action-box {
    .modal-header {
        background-color: $text-black;
        font-weight: bold;
        border: 2px solid $text-color;
        text-align: center;

        .close {
            color: $text-color;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}

@media (min-width: 360px) {
}

@media (min-width: 500px) {
    #logo-message-box {
        .logo-box {
            width: 220px;
        }

        .secondary-logo-box {
            width: 325px;
        }

        .message-box {
        }
    }
}

@media (min-width: 768px) {
    .page-bck {
        //background-image: url( "../img/bg-tablet.jpg" );
    }

    #form-box {
        .content {
            padding: 28px 6rem;
        }
    }

    .big-copy {
    }
    .omg-size {
        font-size: 22px;
    }
    #main {
        &.main-content-h {
        }

        &.main-content-v {
            height: calc(100vh - 120px);
            overflow-y: auto;
            @include scrollbars(13px, $itw-verde, $nero);
        }

        &.main-content-vl {
        }
    }

    #message-box {
        // padding: 2.5rem 4rem;

        .main-title {
            font-size: 88px;
        }

        .msg {
            font-size: 28px;
        }

        .msg-1 {
            font-size: 40px;
        }

        .msg-2 {
            font-size: 30px;
        }

        .msg-3 {
            font-size: 18px;
        }

        .omg {
            font-size: 22px;
        }

        .message {
            font-size: 22px;
            line-height: 1.3;

            span {
                //display: block;
            }
        }
    }

    #logo-message-box {
        min-width: 700px;
        margin-left: auto;
        margin-right: auto;

        .logo-box {
            //width:  220px;
            //margin: 0;
        }

        .message-box {
            .msg-1 {
                font-size: 40px;
            }

            .msg-2 {
                font-size: 50px;
            }

            .msg-3 {
                font-size: 40px;
            }

            .omg {
                font-size: 22px;
            }

            .message {
                font-size: 22px;
                line-height: 1.3;

                span {
                    display: block;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .page-bck {
        //background: url(../img/bck-footer.png) right bottom -40px no-repeat;
        //background-size: contain;
    }
    h1.title {
        font-size: 26px;
    }

    hr {
        width: 35%;
    }

    .title-filepond {
        font-size: 16px;
    }

    .subtitle-filepond {
        font-size: 13px;
    }

    #main {
        padding-left: 2vw;
        padding-right: 2vw;

        &.main-content-h {
            //flex-direction: row;
            align-items: center;
        }

        #logo-message-box {
            min-width: auto;

            .logo-box {
                width: auto;
                max-width: 300px;
                margin: 0 auto;
            }

            .secondary-logo-box {
                width: auto;
                max-width: 400px;
                margin-top: 100px;
            }

            .message-box {
                margin-top: 7rem;

                text-align: center;

                // .msg-1 { font-size: 90px; }
                // .msg-2 { font-size: 80px; }
                // .msg-3 { font-size: 50px; }

                .omg {
                    font-size: 30px;
                }

                .message {
                    font-size: 30px;

                    span {
                        display: block;
                    }
                }
            }
        }

        #form-box-wrapper {
            margin-top: 50px;
            min-width: 800px;
        }
    }

    #message-box {
        // padding: 2.5rem 4rem;

        .main-title {
            font-size: 136px;
        }

        .msg-1 {
            font-size: 45px;
        }

        .msg-2 {
            font-size: 34px;
        }

        .msg-3 {
            font-size: 20px;
            max-width: 1200px;
        }

        .omg {
            font-size: 30px;
        }

        .message {
            font-size: 30px;

            span {
                //display: block;
            }
        }

        .itw-border {
            &:before {
            }
            .inner.homepage {
                flex-direction: row;
                align-items: unset;
                &:before {
                }
                .divider-box {
                    display: none;
                }
            }
        }
    }

    .page-title {
        font-size: 4rem;
    }

    #content-box {
    }

    .big-copy {
    }
}

@media (min-width: 1200px) {
    #form-box {
        //padding: 3.5rem 7rem;
    }

    #message-box {
        //padding: 3.5rem 5rem;

        .main-title {
        }

        .msg {
            font-size: 30px;
        }
    }

    #main #logo-message-box .logo-box {
        max-width: 420px;
    }
    #main #logo-message-box .secondary-logo-box {
        max-width: 550px;
    }

    #logo-message-box {
        //flex-basis: 66.66%;

        //.logo-box {
        //    width:     auto;
        //    max-width: 520px;
        //    margin:    0 auto;
        //}

        .message-box {
            text-align: center;

            .msg-1 {
                font-size: 50px;
            }

            .msg-2 {
                font-size: 80px;
            }

            .msg-3 {
                font-size: 50px;
            }

            .omg {
                font-size: 30px;
            }

            .message {
                font-size: 30px;

                span {
                    display: block;
                }
            }
        }
    }

    #form-box-wrapper {
        //flex-basis: 33.333%;
    }
}

@media (min-width: 1400px) {
}

@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
}
